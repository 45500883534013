import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import Seo from "../components/seo";
import { GatsbyImage } from "gatsby-plugin-image";
import ContactPerson from "../components/contactPerson";

const Success = ({ data }) => {
  const uuid = getUuid();
  const [requestStatus, setRequestStatus] = useState(true);

  function getUuid() {
    if (typeof window !== "undefined") {
      return window.location.href.split(/[? ]+/).pop();
    }
    return null;
  }
  useEffect(() => {
    fetch(`${process.env.MAIL_SERVICE_SENDMAIL}/${uuid}`, {
      method: "POST",
    })
      .then((res) => {
        if (res.ok) {
          setRequestStatus(true);
        }
      })
      .catch((err) => {
        console.error(`Error: ${err}`);
        setRequestStatus(false);
      });
  }, [uuid]);

  return (
    <Layout lang="de" langPath="nav.success.link">
      <Seo
        title="Double-Opt-In erfolgreich bestätigt – Monday Consulting"
        description="Status of Double-Opt-In"
        keywords="Success, Opt-In, Double, Monday, E-Mail, Bewerber"
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
          marginBlock: 250,
        }}
      >
        {requestStatus ? (
          <>
            <h1>Vielen Dank!</h1>
            <h4>Deine Daten sind bei uns eingegangen.</h4>
          </>
        ) : (
          <>
            <h1>Upps!</h1>
            <h4>
              Da ist etwas schief gelaufen, bitte überprüfe Deinen Link noch
              einmal.
            </h4>
          </>
        )}
      </div>
      {requestStatus && (
        <ContactPerson
          headline="Die weiteren Schritte"
          text="Wir schauen uns Deine Antworten an und melden uns schnellstmöglich bei Dir. Das sollte in der Regel nicht länger als 24 Stunden dauern. Solltest Du vorher schon Fragen haben, ruf gerne an oder schreib uns eine E-Mail."
          contactEmail="jobs@monday-consulting.com"
          contactPhone="+49 40 22 86 842 0"
          contactName="Jannik Bischof"
        >
          <GatsbyImage
            image={data.jannik.childImageSharp.gatsbyImageData}
            alt="Jannik Bischof"
          />
        </ContactPerson>
      )}
    </Layout>
  );
};

export const pageQuery = graphql`
  {
    jannik: file(relativePath: { eq: "profiles/jannik-bischof.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
      }
    }
  }
`;

export default Success;
